<template>
    <div class="row container-lg mx-auto p-0 m-0">
        <div style="min-height: 90vh;width:100%;">
            <div style="height:200px; width:100%; overflow: hidden;" class="z-n1 p-0  opacity-75 d-none d-lg-block">
                <div id="mapa" style="height:140%; width:100%;"></div>
            </div>
            <div class="z-1 row d-none d-lg-flex" style="width: 82%; margin-left: 8%;">
                <div class="col-lg-4 pe-5 flex-column rounded-bottom-0 rounded-top-5" style="margin-top: -50px;">
                    <div class="bg-white pt-3 ps-0 pe-0">
                        <div class="row px-1 m-0 mb-2">
                            <div class="col row d-flex align-items-center px-1 py-1 mx-1 rounded-3 z-1" style="background-color:white;">
                                <p class="my-2 fw-semibold">Cuándo</p>
                                <time-component class="agrandar" :esDeshabilitado=true ref="refInputHours"></time-component>
                            </div>
                        </div>
                        <div class="row p-1 m-0" style="background-color: #f5f5f5;">
                            <p class="col-5 mx-auto mb-0 textoPrimario fw-semibold">Precio</p>
                            <p class="text-center text-black fw-bold fs-4 mb-0" style="margin-top: -5px;">{{  plaza.monthly_price }}€/mes</p>
                        </div>
                        <button class="btn rounded-bottom-5 rounded-top-0 col-12 text-white mt-0"
                            style="background-color: #205760;" data-bs-toggle="modal" data-bs-target="#staticBackdrop" type="button">
                            Reservar
                        </button>
                    </div>
                    <div v-if="plaza.attachments.length > 1" id="carouselExample1" class="carousel slide col-12" >
                        <div class="carousel-inner rounded-5 mt-4 col-12">
                            <div class="carousel-item " v-for="(item, index) in plaza.attachments" :key="index" :class="{ 'active': index === 0 }">
                                <img :src="item" class="d-block w-100 carrusel1" alt="...">
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample1" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExample1" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                    <div v-else id="carouselExample" class="slide col-12">
                        <div class="carousel-inner rounded-5 mt-4 col-12">
                            <div class="carousel-item active">
                                <img v-if="plaza.attachments.length == 1" :src=plaza.attachments[0] class="d-block w-100" alt="...">
                                <img v-else src="../assets/imagenPredefinida.jpg" class="d-block w-100" alt="...">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8 ps-5 mt-5 pt-3">
                    <div class="col">
                        <h3 class="mb-4 textoPrimario fw-semibold fs-4">{{ plaza.direction }}
                        </h3>
                        <h6 style="color: #133C44;" class="fw-semibold">Servicios</h6>
                        <div class="row m-0">
                            <div class="col" v-if="plaza.services.security">
                                <img  class="iconoLista me-3" src="./../assets/iconoSeguridad.png">
                                <span class="ms-2">Seguridad</span>
                            </div>
                            <div class="col" v-if="plaza.services.disabled_people_service">
                                <img  class="iconoLista me-3" src="./../assets/iconoAccesoAdaptado.png">
                                <span class="ms-2">Accesible</span>
                            </div>
                            <div class="col" v-if="plaza.services.no_columns">
                                <img  class="iconoLista me-3" src="./../assets/iconoColumns.png">
                                <span class="ms-2">Sin columnas</span>
                            </div>
                        </div>
                    
                        <h6 style="color: #133C44;" class="fw-semibold mt-4">Tipo de vehiculo</h6>
                        <p v-if="plaza.vehicle_type == 'car'">Coche</p>
                        <p v-if="plaza.vehicle_type == 'motorbike'">Moto</p>

                        <h6 style="color: #133C44;" class="fw-semibold mt-3">Tamaño</h6>
                        <p class="mb-0" style="color: #888888;"><img src="../../src/assets/iconoAltura.png" height="20"> {{  plaza.height }} cm</p>
                        <p class="mb-0" style="color: #888888;">
                            <img src="../../src/assets/iconoAltura.png" height="20" style="transform: rotate(90deg); display: inline-block;"> {{  plaza.width }} cm
                        </p>

                        <h6 style="color: #133C44;" class="fw-semibold mt-3">Disponibilidad</h6>
                        <div class="d-flex flex-wrap w-100">
                            <span v-if="plaza.availability.monday" class="dia me-4 px-3 py-1 mb-2">Lunes</span>
                            <span v-if="plaza.availability.tuesday" class="dia me-4 px-3 py-1 mb-2">Martes</span>
                            <span v-if="plaza.availability.wednesday" class="dia me-4 px-3 py-1 mb-2">Miercoles</span>
                            <span v-if="plaza.availability.thursday" class="dia me-4 px-3 py-1 mb-2">Jueves</span>
                            <span v-if="plaza.availability.friday" class="dia me-4 px-3 py-1 mb-2">Viernes</span>
                            <span v-if="plaza.availability.saturday" class="dia me-4 px-3 py-1 mb-2">Sabado</span>
                            <span v-if="plaza.availability.sunday" class="dia me-4 px-3 py-1 mb-2">Domingo</span>
                        </div>

                        <h5 class="mt-4 fw-bold" style="color: #133C44;">Descripción</h5>
                        <p>{{ plaza.description }}</p>

                        <h5 class="mt-4">Contacta con el propietario</h5>
                        <a class="btn col-4 py-auto d-flex align-items-center justify-content-center text-center" style="border: 1px solid #133C44; margin-bottom: 20%;" data-bs-toggle="modal" data-bs-target="#staticBackdrop" type="button">
                            Contactar
                        </a>

                    </div>
                </div>
            </div>
            <div class="z-1 row d-lg-none justify-content-center align-items-center" style="width: 82%; margin-left: 9%;">
                <div v-if="plaza.attachments.length > 1" id="carouselExample" class="carousel slide col-md-8 col-sm-10">
                    <div class="carousel-inner rounded-5 mt-4 col-12">
                        <div class="carousel-item" v-for="(item, index) in plaza.attachments" :key="index" :class="{ 'active': index === 0 }">
                            <img :src="item" class="d-block w-100 carrusel1" alt="...">
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>
                <div v-else id="carouselExample" class="slide col-12">
                    <div class="carousel-inner rounded-5 mt-4 col-12">
                        <div class="carousel-item active">
                            <img v-if="plaza.attachments.length == 1" :src=plaza.attachments[0] class="d-block w-100" alt="...">
                            <img v-else src="../assets/imagenPredefinida.jpg" class="d-block w-100" alt="...">
                        </div>
                    </div>
                </div>
                <div class="col-12 mt-2 pt-3 mx-auto">
                    <div class="col">
                        <h3 class="mb-4 textoPrimario fw-semibold fs-4">{{ plaza.direction }}
                        </h3>
                        <h6 style="color: #133C44;" class="fw-semibold">Servicios</h6>
                        <div class="row m-0">
                            <div class="col" v-if="plaza.services.security">
                                <img  class="iconoLista me-3" src="./../assets/iconoSeguridad.png">
                                <span class="ms-2">Seguridad</span>
                            </div>
                            <div class="col" v-if="plaza.services.disabled_people_service">
                                <img  class="iconoLista me-3" src="./../assets/iconoAccesoAdaptado.png">
                                <span class="ms-2">Accesible</span>
                            </div>
                            <div class="col" v-if="plaza.services.no_columns">
                                <img  class="iconoLista me-3" src="./../assets/iconoColumns.png">
                                <span class="ms-2">Sin columnas</span>
                            </div>
                        </div>

                        <h6 style="color: #133C44;" class="fw-semibold mt-4">Tipo de vehiculo</h6>
                        <p v-if="plaza.vehicle_type == 'car'">Coche</p>
                        <p v-if="plaza.vehicle_type == 'motorbike'">Moto</p>

                        <h6 style="color: #133C44;" class="fw-semibold mt-3">Tamaño</h6>
                        <p class="mb-0" style="color: #888888;"><img src="../../src/assets/iconoAltura.png" height="20"> {{  plaza.height }} cm</p>
                        <p class="mb-0" style="color: #888888;">
                            <img src="../../src/assets/iconoAltura.png" height="20" style="transform: rotate(90deg); display: inline-block;"> {{  plaza.width }} cm
                        </p>

                        <h6 style="color: #133C44;" class="fw-semibold mt-3">Disponibilidad</h6>
                        <div class="d-flex flex-wrap w-100">
                            <span v-if="plaza.availability.monday" class="dia me-4 px-3 py-1 mb-2">Lunes</span>
                            <span v-if="plaza.availability.tuesday" class="dia me-4 px-3 py-1 mb-2">Martes</span>
                            <span v-if="plaza.availability.wednesday" class="dia me-4 px-3 py-1 mb-2">Miercoles</span>
                            <span v-if="plaza.availability.thursday" class="dia me-4 px-3 py-1 mb-2">Jueves</span>
                            <span v-if="plaza.availability.friday" class="dia me-4 px-3 py-1 mb-2">Viernes</span>
                            <span v-if="plaza.availability.saturday" class="dia me-4 px-3 py-1 mb-2">Sabado</span>
                            <span v-if="plaza.availability.sunday" class="dia me-4 px-3 py-1 mb-2">Domingo</span>
                        </div>

                        <h5 class="mt-4 fw-bold" style="color: #133C44;">Descripción</h5>
                        <p>{{ plaza.description }}</p>

                        <div style="height:170px; width:100%; overflow: hidden;"
                            class="z-n1 p-0  opacity-75 d-lg-none d-block">
                            <div id="mapa2" style="height:110%; width:100%;"></div>
                        </div>
                        <h5 class="mt-4 fw-bold col-md-8 col-sm-10 mx-auto">Contacta con el propietario</h5>
                        <a class="btn col-md-6 col-xs-8 py-auto d-flex align-items-center justify-content-center text-center mx-auto mt-4" style="border: 1px solid #133C44" data-bs-toggle="modal" data-bs-target="#staticBackdrop" type="button" >
                            Contactar
                        </a>
                    </div>
                </div>
                <div class="pt-3 ps-0 pe-0 col-md-8 col-sm-10 rounded-bottom-5 shadow my-5">
                    <div class="row px-1 m-0 mb-2">
                        <div class="col row d-flex align-items-center px-1 py-1 mx-1 rounded-3 z-1" style="background-color:white;">
                            <p class="mb-1 fw-semibold">Cuándo</p>
                            <time-component class="agrandar" :esDeshabilitado=true ref="refInputHours"></time-component>
                        </div>
                    </div>
                    <div class="row p-1 m-0" style="background-color: #f5f5f5;">
                        <p class="col-5 mx-auto mb-0 textoPrimario fw-semibold">Precio</p>
                        <p class="text-center text-black fw-bold fs-2 mb-0" style="margin-top: -5px;">{{  plaza.monthly_price }}€/mes</p>
                    </div>
                    <button class="btn rounded-bottom-5 rounded-top-0 col-12 text-white my-0" data-bs-toggle="modal" data-bs-target="#staticBackdrop" type="button"
                        style="background-color: #205760;">Reservar</button>
                </div>
            </div>
        </div>
        <!-- Modal -->
        <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="staticBackdropLabel">Datos del dueño</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body" >
                        <div class="w-100 d-flex align-items-center justify-content-center" >
                            <span class="px-3 py-1 rounded" style="border: 1px solid #133C44;">
                                Usuario: {{ plaza.user.username }}
                            </span>
                        </div>
                        <div class="w-100 d-flex align-items-center justify-content-center pt-3" >
                            <span class="px-3 py-1 rounded" style="border: 1px solid #133C44;">
                                Nombre: {{ plaza.user.first_name }} {{ plaza.user.last_name }}
                            </span>
                        </div>
                        <div class="w-100 d-flex align-items-center justify-content-center pt-3" >
                            <span class="px-3 py-1 rounded" style="border: 1px solid #133C44;">
                                Email: {{ plaza.user.email }}
                            </span>
                        </div>
                        <div class="w-100 d-flex align-items-center justify-content-center pt-3" >
                            <span class="px-3 py-1 rounded" style="border: 1px solid #133C44;">
                                Telefono: {{ plaza.user.phone_number }}
                            </span>
                        </div>
                    </div>
                    <!--<div v-else>
                        <div style="height: 30vh" class="d-flex" >
                            <img src="./../assets/denegacion.svg" class="h-100 mx-auto">
                        </div>
                        <p class="text-center">Debes estar logueado para ver los datos de los dueños</p>
                        <div class="d-flex">
                            <span class="mx-auto p-3 botonLogin text-white rounded mb-4">
                                <router-link to="Login" class="text-decoration-none text-white">Inicia Sesion</router-link> o 
                                <router-link to="Register" class="text-decoration-none text-white">Registrate</router-link>
                            </span>
                        </div>
                    </div>-->
                </div>
            </div>
        </div>
        <spinner-component id="spinner" class="d-none"></spinner-component>
    </div>
</template>
  
<script>
import L from 'leaflet';
import axios from 'axios';
import 'leaflet.markercluster';
import TimeComponent from './../components/TimeComponent.vue'
import { mapState } from 'vuex';
import SpinnerComponent from '../components/SpinnerComponent.vue'

export default {
    name: 'DetailsPlaza',
    data: function(){
        return{
            map:{},
            map2: {},
            markers: L.markerClusterGroup(),
            markers2: L.markerClusterGroup(),
            plaza: {
                services: {
                    security: false,
                    disabled_people_service: false,
                    no_columns: false
                },
                availability: {
                    monday: false,
                    tuesday: false,
                    wednesday: false,
                    thursday: false,
                    friday: false,
                    saturday: false,
                    sunday: false
                },
                attachments:[],
                user:{}
            }
        }
    },
    computed:{
      ...mapState(['isLoggedIn'])
    },
    components: {
        TimeComponent,
        SpinnerComponent
    },
    methods: {
        MeterMarcador(centro){
            var divIcon = L.divIcon({
                // Establece el contenido HTML del icono
                html: '<div class="marcador"><p style="font-size:0.8rem">TU PLAZA</p></div><div class="flecha-down mx-auto"></div>',
                iconSize: [38, 40], // size of the icon
                shadowSize: [50, 64], // size of the shadow
                iconAnchor: [22, 40], // point of the icon which will correspond to marker's location
                popupAnchor: [-3, -76]
            });

            var marker = L.marker([centro[0], centro[1]], {icon : divIcon});
            this.markers.clearLayers();
            
            this.map.addLayer(this.markers);
            this.markers.addLayer(marker);
        },
    },
    async mounted() {
        var spinner = document.getElementById("spinner")
        spinner.classList.remove("d-none")
        var nombreMapa = "mapa"
        if(window.innerWidth < 992){
            nombreMapa += "2"
        }
        this.map = L.map(nombreMapa, {zoomControl: true,zoom:1,zoomAnimation:false,fadeAnimation:true,markerZoomAnimation:true, dragging: false,scrollWheelZoom: false}).setView([39.850931195377946, -3.1256103515625004], 5)
        
        L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
        maxZoom: 20,
        minZoom: 6
        }).addTo(this.map);

        const plazaId = this.$route.params.numero;

        try {
            const response = await axios.get('https://api.apparca.es/v1/api/parking_lots/'+plazaId);
            this.plaza = response.data;
            
    
            let horas = {
                startTime: null,
                endTime: null
            }
            horas.startTime= (this.plaza.start_hour < 10 ? "0" : "") + this.plaza.start_hour + ":"+ (this.plaza.start_minute < 10 ? "0" : "") + this.plaza.start_minute
            horas.endTime= (this.plaza.end_hour < 10 ? "0" : "") + this.plaza.end_hour + ":"+ (this.plaza.end_minute < 10 ? "0" : "") + this.plaza.end_minute
            this.$refs.refInputHours.CambiarHoras(horas)

            this.map.setView([this.plaza.coordinates.latitude, this.plaza.coordinates.longitude], 15)
            this.MeterMarcador([this.plaza.coordinates.latitude, this.plaza.coordinates.longitude])
            
            spinner.classList.add("d-none")
        } catch (error) {
            // TODO: Redirigir a un 500
        }
    } 
}

</script>
  
<style>
.textoPrimario {
    color: #133C44;
}

.custom-dropdown .dropdown-toggle::after {
    display: none;
    /* Ocultar el icono de desplegar predeterminado */
}

.dia{
    background-color: #388697;
    border-radius: 50px;
    color: white;
}

.custom-dropdown .dropdown-toggle {
    background-image: url('../../src/assets/desplegable.png');
    /* Reemplaza 'ruta_de_la_imagen.png' con la URL de tu imagen */
    background-repeat: no-repeat;
    background-position: right center;
    /* Puedes ajustar la posición de la imagen */
    background-size: 20px;
    /* Ajusta el tamaño de la imagen según tus necesidades */
    padding-right: 30px;
    /* Ajusta el espacio para que la imagen no se superponga con el texto */
}

.carrusel1{
    height: 220px;
}

.icono {
    width: 30%;
}

.filtroMovil {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 20px 20px;
}

@media screen and (max-width: 1200px) {
    .carrusel1{
        height: 180px;
    }
}

@media screen and (max-width: 992px) {
    .filtroVista {
        display: none;
    }
    .carrusel1{
        height: 300px;
    }
}

@media screen and (max-width: 768px) {
    .icono {
        width: 15%;
    }

    .plazaItem {
        background-color: rgba(246, 246, 246, 0.511);
    }
}</style>
<template>
  <div class="demo-time-range row m-0" >
    <el-time-select class="col-6 p-0 pe-1" 
      v-model="time.startTime"
      placeholder="Desde"
      start="00:00"
      step="00:15"
      end="23:45" :disabled="esDeshabilitado"
      @change="setData"
    />
    <el-time-select class="col-6 p-0 ps-1" 
      v-model="time.endTime"
      placeholder="Hasta"
      start="00:00"
      step="00:15"
      end="23:45" :disabled="esDeshabilitado"
      @change="setData"
    />
  </div>
</template>

<script>
export default {
  name: 'TimeComponent',
  props:{
    esDeshabilitado: {
      type: Boolean,
      default: false,
    },
  },
  data: function() {
        return {
            // TODO: crear variables de datos para el funcionamiento del componente
            time:{
              startTime:"",
              endTime:""
            }
        }
    },
  methods: {
    setData() {
      this.$emit("getDataHoras", JSON.stringify(this.time));
    },
    CambiarHoras(horas){
      if(horas != null){
        var inputs = document.querySelectorAll('.el-input__inner');
        this.time.startTime = horas.startTime
        this.time.endTime = horas.endTime
        inputs[0].value = horas.startTime
        inputs[1].value = horas.endTime
      }
        
    }
  }
}
</script>

<style>
.select-trigger{
    height: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.el-input{
    height: 100% !important;
    box-shadow: 0 0 5px rgba(0,0,0,0.1) !important;
    border: 2px solid #1C4F58;
    border-radius: 5px !important;
    color: #1C4F58 !important;
    
}

.el-icon svg path{
  fill: #1C4F58;
  height: 200px !important;
}

.el-input__inner::placeholder{
  color: #1C4F58 !important;
  -webkit-text-fill-color: #1C4F58 !important
}

.el-input__inner{
  color: #1C4F58 !important;
  -webkit-text-fill-color: #1C4F58 !important
}

.demo-time-range{
  height: 55px !important;
}

@media screen and (max-width: 1400px) {
  .agrandar .el-select{
    width: 100% !important;
    padding: 0px !important;
    margin-bottom: 10px !important;
  }

  .agrandar{
    height: 130px !important;
  }
}

@media screen and (max-width: 992px) {
  .agrandar .el-select{
    width: 49%;
    padding: 0px !important;
    margin-bottom: 0px !important;
  }

  .agrandar .el-select:first-child{
    margin-right: 2% !important;
  }

  .agrandar{
    height: 60px !important;
  }
}

@media screen and (max-width: 576px) {
  .agrandar .el-select{
    width: 100% !important;
    padding: 0px !important;
    margin-bottom: 15px !important;
  }
  .agrandar{
    height: 140px !important;
  }
}
</style>
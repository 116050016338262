<template>
  <div class="row container mx-auto p-0 m-0" style="min-height: 90vh;">
    <div class="col-12 p-0 pt-4 mt-1">
      <h2 class="text-center">Mis favoritos</h2>
      <hr class="opacity-50" style="border: 1px solid #133C44;">
      <!--<div class="row" id="imagen" v-if="!this.isLoggedIn">
          <img src="../assets/denegacion.svg" class="col-lg-5 col-md-8 col-10 mx-auto">
          <h3 class="text-center mt-3">Debes iniciar sesion para ver tus favoritos</h3>
          <div class="d-flex mt-2">
              <span class="mx-auto p-3 botonLogin text-white rounded mb-4">
                <router-link to="Login" class="text-decoration-none text-white">Inicia Sesion</router-link> o 
                <router-link to="Register" class="text-decoration-none text-white">Registrate</router-link>
              </span>
          </div>
      </div>-->
      <div class="row" id="imagen" v-if="plazas.length == 0">
          <img src="../assets/Empty.png" class="col-lg-5 col-md-8 col-10 mx-auto">
          <h3 class="text-center mt-3">No tienes ninguna plaza guardada todavía</h3>
          <div class="d-flex mt-4">
              <router-link to="VistaMapa" class="mx-auto px-4 py-3 botonLogin text-white rounded-5 text-decoration-none">Encontrar más plazas</router-link>
          </div>
      </div>
      <div v-else class="col-12 mx-auto px-sm-0 px-4">
        <div v-for="(item, index) in this.plazas" :key="index" class="row m-0 rounded-4 plazaItem overflow-hidden my-5" style="background-color: #f5f5f5;">
          <div class="col-2 p-0 d-md-block d-none">
            <a :href="'/parking_lot/' + item.parking_lot.id">
              <img :src=item.parking_lot.imagenSrc alt="Img1" class="w-100">
            </a>
          </div>
          <div class="col-md-6 " style="background-color: #f5f5f5;">
            <div class="row h-100 align-items-center">
              <a :href="'/parking_lot/' + item.parking_lot.id" class="fw-bold mb-1 textoPrimario text-decoration-none">{{ item.parking_lot.direction }}</a>
              <div class="col-4">
                <p class="mb-1 fw-semibold textoPrimario">Horario</p>
                <p class="mb-0" style="color: #888888;">{{item.parking_lot.start_hour}}:{{ item.parking_lot.start_minute }}
                         - {{item.parking_lot.end_hour}}:{{ item.parking_lot.end_minute }}</p>
              </div>
              <div class="col-3">
                <p class="mb-1 fw-semibold textoPrimario">Ancho</p>
                <p class="mb-0" style="color: #888888;"><img src="../../src/assets/iconoAltura.png" class="me-1" height="20" style="transform: rotate(90deg);">{{item.parking_lot.width}}cm</p>
              </div>
              <div class="col-3">
                <p class="mb-1 fw-semibold textoPrimario">Largo</p>
                <p class="mb-0" style="color: #888888;"><img src="../../src/assets/iconoAltura.png" height="20">{{item.parking_lot.height}}cm</p>
              </div>
              <span @click="this.QuitarFav(item.parking_lot.id)" class="fas fa-star col-2 text-end star favorito" ></span>
            </div>
          </div>
          <div class="col-md-3 col-6 d-flex align-items-center justify-content-center" style="background-color: #f5f5f5;">
            <div>
              <p class="fw-semibold mb-3" style="color: #133C44;">Servicios</p>
              <div class="mx-auto px-3 pb-2" style="display: flex; justify-content: center;">
                  <img v-if="item.parking_lot.services.security" class="iconoLista me-3" src="./../assets/iconoSeguridad.png">
                  <img v-if="item.parking_lot.services.disabled_people_service" class="iconoLista me-3" src="./../assets/iconoAccesoAdaptado.png">
                  <img v-if="item.parking_lot.services.no_columns" class="iconoLista" src="./../assets/iconoColumns.png">
              </div>
            </div>
          </div>
          <div class="col-md-1 col-6 d-flex align-items-center justify-center" style="background-color: #CFE2E4;">
            <div>
              <p class="fw-semibold mb-0 " style="color: #133C44;">Precio</p>
              <p class="fw-bold mb-0 fs-3 text-center" style="color: #000000;">{{item.parking_lot.monthly_price}}€</p>
            </div>
          </div>
        </div>
        <div class="d-flex mt-2">
            <router-link to="VistaMapa" class="mx-auto p-3 botonLogin text-white rounded-5 text-decoration-none">Encontrar más plazas</router-link>
        </div>
      </div>
    </div>
    <spinner-component id="spinner" class="d-none"></spinner-component>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import axios from 'axios';
import SpinnerComponent from '../components/SpinnerComponent.vue'

export default {
  name: 'MisFavoritos',
  data: function() {
      return {
        plazas: [],
      }
  },
  computed:{
      ...mapState(['isLoggedIn']),
      ...mapState(['token'])
  },
  components:{
    SpinnerComponent
  },
  methods:{
    QuitarFav(id){
      let favoritosSession = JSON.parse(localStorage.getItem('favoritos'))
      
      var indice = favoritosSession.indexOf(id);
      if (indice !== -1) {
        favoritosSession.splice(indice, 1);
      }

      localStorage.setItem('favoritos', JSON.stringify(favoritosSession));

      const apiUrlFavorites = 'https://api.apparca.es/v1/api/favorites/'+ id;
      const headers = {
        Authorization: `Bearer ${this.token}`,
      };
      axios.delete(apiUrlFavorites, { headers })
      .then(() => {
      })
      .catch(error => {
        console.error(error);
      });

      this.plazas = this.plazas.filter(function(objeto) {
        return objeto.parking_lot.id !== id;
      });

    },
  },
  mounted(){
    var spinner = document.getElementById("spinner")
    spinner.classList.remove("d-none")
    if(this.isLoggedIn){
      let restaurarFavs = []
      const apiUrl = 'https://api.apparca.es/v1/api/favorites';
      const headers = {
        Authorization: `Bearer ${this.token}`,
      };
      axios.get(apiUrl, { headers })
      .then(response => {
        this.plazas = response.data.items
        spinner.classList.add("d-none")
        this.plazas.forEach(plaza => {
          plaza = plaza.parking_lot
          restaurarFavs.push(plaza.id)
          plaza.start_hour= (plaza.start_hour < 10 ? "0" : "") + plaza.start_hour 
          plaza.start_minute = (plaza.start_minute < 10 ? "0" : "") + plaza.start_minute
          plaza.end_hour = (plaza.end_hour < 10 ? "0" : "") + plaza.end_hour 
          plaza.end_minute = (plaza.end_minute < 10 ? "0" : "") + plaza.end_minute
          if(plaza.preview != null){
            plaza.imagenSrc = plaza.preview;
          }else{
            plaza.imagenSrc = "https://t4.ftcdn.net/jpg/04/73/25/49/360_F_473254957_bxG9yf4ly7OBO5I0O5KABlN930GwaMQz.jpg";
          }
        });
        localStorage.setItem('favoritos', JSON.stringify(restaurarFavs));
      })
      .catch(error => {
        console.error(error);
        spinner.classList.add("d-none")
      });
    }else{
      let parking_lot_ids = {
        parking_lot_ids: JSON.parse(localStorage.getItem('favoritos')) != null ? JSON.parse(localStorage.getItem('favoritos')) : []
      }
      if(parking_lot_ids.parking_lot_ids.length > 0){
        const apiUrl = 'https://api.apparca.es/v1/api/bulk_parking_lots';
        axios.post(apiUrl, parking_lot_ids)
        .then(response => {
          this.plazas = response.data.items
          spinner.classList.add("d-none")
          this.plazas.forEach(plaza => {
            plaza.parking_lot = plaza
            plaza.parking_lot.start_hour= (plaza.start_hour < 10 ? "0" : "") + plaza.start_hour 
            plaza.parking_lot.start_minute = (plaza.start_minute < 10 ? "0" : "") + plaza.start_minute
            plaza.parking_lot.end_hour = (plaza.end_hour < 10 ? "0" : "") + plaza.end_hour 
            plaza.parking_lot.end_minute = (plaza.end_minute < 10 ? "0" : "") + plaza.end_minute
            if(plaza.parking_lot.preview != null){
              plaza.parking_lot.imagenSrc = plaza.preview;
            }else{
              plaza.parking_lot.imagenSrc = "https://t4.ftcdn.net/jpg/04/73/25/49/360_F_473254957_bxG9yf4ly7OBO5I0O5KABlN930GwaMQz.jpg";
            }
          });
        })
        .catch(error => {
          console.error(error);
          spinner.classList.add("d-none")
        });
      }else{
        spinner.classList.add("d-none")
      }
    }

  }
}

</script>

<style>

.iconoLista{
  height: 35px;
}

.checkbox-wrapper-40 {
    --borderColor: #133C44
    ;
    --borderWidth: .100em;
  }

  .checkbox-wrapper-40 label {
    display: block;
    max-width: 100%;
    margin: 0 auto;
  }

  .checkbox-wrapper-40 input[type=checkbox] {
    -webkit-appearance: none;
    appearance: none;
    vertical-align: middle;
    background: #fff;
    font-size: 1em;
    border-radius: 0.125em;
    display: inline-block;
    border: var(--borderWidth) solid var(--borderColor);
    width: 1em;
    height: 1em;
    position: relative;
  }
  .checkbox-wrapper-40 input[type=checkbox]:before,
  .checkbox-wrapper-40 input[type=checkbox]:after {
    content: "";
    position: absolute;
    background: var(--borderColor);
    width: calc(var(--borderWidth) * 3);
    height: var(--borderWidth);
    top: 50%;
    left: 20%;
    transform-origin: left center;
  }
  .checkbox-wrapper-40 input[type=checkbox]:before {
    transform: rotate(45deg) translate(calc(var(--borderWidth) / -2), calc(var(--borderWidth) / -2)) scaleX(0);
    transition: transform 200ms ease-in 200ms;
  }
  .checkbox-wrapper-40 input[type=checkbox]:after {
    width: calc(var(--borderWidth) * 5);
    transform: rotate(-45deg) translateY(calc(var(--borderWidth) * 2)) scaleX(0);
    transform-origin: left center;
    transition: transform 200ms ease-in;
  }
  .checkbox-wrapper-40 input[type=checkbox]:checked:before {
    transform: rotate(45deg) translate(calc(var(--borderWidth) / -2), calc(var(--borderWidth) / -2)) scaleX(1);
    transition: transform 200ms ease-in;
  }
  .checkbox-wrapper-40 input[type=checkbox]:checked:after {
    width: calc(var(--borderWidth) * 5);
    transform: rotate(-45deg) translateY(calc(var(--borderWidth) * 2)) scaleX(1);
    transition: transform 200ms ease-out 200ms;
  }
  .checkbox-wrapper-40 input[type=checkbox]:focus {
    outline: calc(var(--borderWidth) / 2) dotted rgba(0, 0, 0, 0.25);
  }
    .textoPrimario {
      color: #133C44;
    }

    .custom-dropdown .dropdown-toggle::after {
      display: none;
      /* Ocultar el icono de desplegar predeterminado */
    }

    .custom-dropdown .dropdown-toggle {
      background-image: url('../../src/assets/desplegable.png');
      /* Reemplaza 'ruta_de_la_imagen.png' con la URL de tu imagen */
      background-repeat: no-repeat;
      background-position: right center;
      /* Puedes ajustar la posición de la imagen */
      background-size: 20px;
      /* Ajusta el tamaño de la imagen según tus necesidades */
      padding-right: 30px;
      /* Ajusta el espacio para que la imagen no se superponga con el texto */
    }

    .icono {
      min-height: 100%;
      width:auto;
    }

    @media screen and (max-width: 768px) {
      .plazaItem {
        background-color: rgba(246, 246, 246, 0.511);
      }
    }
</style>

<template>
    <div class="fondo">
      <div class="formulario">
        <v-stepper 
        v-model="currentStep"
        prev-text="Anterior"
        next-text="Siguiente"
        :items="items">
        <transition :name="this.getTransitionName">
          <div>
            <div v-if="currentStep === 1">
              <v-card flat class="step">
                <div class="row">
                  <h3 class="text-center col-xl-6 col-lg-8 col-11 mx-auto mt-3 mb-1">¡¡Felicidades!! Llegas a tiempo para publicar tu plaza gratis</h3>
                </div>
                <div class="row">
                  <img class="col-xl-5 col-lg-6 col-md-8 col-sm-8 col-10 my-3 mx-auto" src="./../assets/createPhoto2.png">
                </div>
                <div class="row">
                  <h5 class="text-center col-11 mx-auto mt-4">Podrás anunciarte gratis hasta el 1 agosto de 2024</h5>
                </div>
                
              </v-card>
            </div>
            <div v-if="currentStep === 2">
              <v-card flat class="step d-flex align-items-center justify-content">
                <div class="w-100" >
                  <div class="w-100 px-4 row mx-0">
                    <div class="col-lg me-4">
                      <div class="row m-0 my-4">
                        <div class="col-12 p-0">
                          <div id="inputDireccion">
                            <address-browser-component ref="refInputAddress" @getDataBrowser="getDataBrowser"></address-browser-component>
                          </div>
                          <div class="invalid-feedback">
                            Selecciona una direccion correcta
                          </div>
                        </div>
                        <div class="col-12 mt-3 p-0">
                          <input-car ref="refInputCar" @getDataCar="getDataCar"></input-car>
                        </div>
                        <div class="col-12 p-0 mt-3">
                          <div id="inputHoras">
                            <time-component ref="refInputHours" @getDataHoras="getDataHoras"></time-component>
                          </div>
                          <div class="invalid-feedback">
                            Horas incorrectas
                          </div>
                          <div class="form-check mt-3 ms-4">
                            <input class="form-check-input" type="checkbox" v-model="fullDay" id="flexCheckDefault">
                            <label class="form-check-label" for="flexCheckDefault">
                              Alquilar todo el dia
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg" id="map" style="min-height: 200px;">
                    </div>
                  </div>
                  <div class="w-100 px-4 row my-3 mx-0">
                    <div class="col-lg me-4">
                      <p>Selecciona los días que alquilaras tu plaza</p>
                      <div id="inputDias">
                        <v-combobox
                          class="mt-3"
                          v-model="select"
                          :items="itemsDias"
                          label="Dias" 
                          multiple
                          chips
                          @update:modelValue="onInput"
                        />
                      </div>
                      <div class="invalid-feedback">
                        Debes seleccionar algun día 
                      </div>
                    </div>
                    <div class="col-lg row m-0">
                      <p class="mt-3 mb-0">Tamaño (cm)</p>
                      <div class="row m-0" id="inputTamaño">
                        <div class="col-sm-6 d-flex align-items-center my-3">
                          <input type="number" min="0" step="0.1" v-model="plazaCreate.height" placeholder="Largo (cm)" class="form-control inputSize">
                        </div>
                        <div class="col-sm-6 d-flex align-items-center">
                          <input type="number" min="0" step="0.1" v-model="plazaCreate.width" placeholder="Ancho (cm)" class="form-control inputSize">
                        </div>
                      </div>
                      <div class="invalid-feedback">
                        Tamaño de la plaza incorrecto
                      </div>
                    </div>
                  </div>
                </div>
              </v-card>
            </div>
            <div v-if="currentStep === 3">
              <v-card flat class="step d-flex align-items-center justify-content">
                <div class="w-100 row mx-0" >
                  <div class="w-100 px-4 row mx-0 " style="height: 100px;">
                    <v-file-input
                      label="Sube las imagenes de tu plaza"
                      variant="filled"
                      prepend-icon="mdi-camera"
                      @change="handleImageUpload"
                      multiple
                      accept="image/*" class="mb-0"
                    ></v-file-input>
                  </div>
                  <div class="container">
                    <div class="d-flex flex-wrap">
                      <div
                        class="me-5 ms-3 my-3"
                        v-for="(imageBlob, index) in plazaCreate.attachments"
                        :key="index"
                        >
                        <div class="parent-div">
                          <img :src="imageBlob" class="imagenesSubidas" />
                          <v-icon @click="removeImage(index)" class="DeleteIcon">mdi-delete</v-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-10 mx-auto row my-4 ">
                    <div class="col-lg mb-3 me-4 d-flex align-items-center">
                      <div>
                        <p>Servicios</p>
                        <div class="form-check form-switch d-flex align-items-center ms-4 mb-3" style="height: 40px;">
                          <input ref="myCheckbox0" v-model="plazaCreate.services.security" class="form-check-input h-50 mt-0 me-3" type="checkbox" role="switch" >
                          <img src="../assets/iconoSeguridad.png" class="h-100">
                          <span class="ms-3">Seguridad</span>
                        </div>
                        <div class="form-check form-switch d-flex align-items-center ms-4 mb-3" style="height: 40px;">
                          <input ref="myCheckbox1" v-model="plazaCreate.services.no_columns" class="form-check-input h-50 mt-0 me-3" type="checkbox" role="switch" >
                          <img src="../assets/iconoColumns.png" class="h-100">
                          <span class="ms-3">Sin columnas</span>
                        </div>
                        <div class="form-check form-switch d-flex align-items-center ms-4" style="height: 40px;">
                          <input ref="myCheckbox2" v-model="plazaCreate.services.disabled_people_service" class="form-check-input h-50 mt-0 me-3" type="checkbox" role="switch" >
                          <img src="../assets/iconoAccesoAdaptado.png" class="h-100">
                          <span class="ms-3">Acceso adaptado</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg row mx-0">
                      <div class="row mb-3">
                        <p>Precio mensual</p>
                        <div id="inputPrecio">
                          <slider-price-component :max="200" :min="0" ref="sliderPrice"  @getDataPrecio="getDataPrecio"></slider-price-component>
                        </div>
                        <div class="invalid-feedback">
                          Precio incorrecto
                        </div>
                      </div>
                      <div class="row">
                        <p class="mb-0">Descripción</p>
                        <v-container fluid>
                          <v-textarea
                            v-model="plazaCreate.description"
                            clearable
                            clear-icon="mdi-close-circle"
                            label="Cuéntanos mas de tu plaza"
                            counter="200"
                            @input="limitCharacters"
                          ></v-textarea>
                        </v-container>
                      </div>
                    </div>
                  </div>
                  <div class="m-0 d-flex" v-if="items.length == 3">
                    <a class="mx-auto py-2 px-4 rounded-3 post_button" @click="Publicar">Publicar</a>
                  </div>
                </div>
                
              </v-card>
            </div>
            <div v-if="currentStep == 4">
              <v-card flat class="step">
                <div class="w-100 pt-5" >
                  <div class="w-100 px-4 row mx-0" v-if="!isLoggedIn">
                    <div class="col-lg-6 registroCreate">
                      <form @submit.prevent="Registrarse()" class="px-3">
                        <h2 class="title">Regístrate</h2>
                        <div class="w-100 p-0 m-0 d-flex align-items-center justify-content-center">
                          <div class="w-100">
                            <div class="input-field  mx-auto" id="inputuser">
                              <i class="fas fa-user"></i>
                              <input v-model="userRegister.first_name" required autocomplete="new-password" type="text" placeholder="Nombre Completo" />
                            </div>
                          </div>
                        </div>
                        <div class="w-100 p-0 m-0 d-flex align-items-center justify-content-center">
                          <div class="w-100">
                            <div class="input-field  mx-auto" id="inputuser">
                              <i class="fas fa-user"></i>
                              <input v-model="userRegister.username" required autocomplete="new-password" type="text" placeholder="Usuario" />
                            </div>
                            <div class="invalid-feedback text-center">
                                Usuario ya existente
                            </div>
                          </div>
                        </div>
                        <div class="w-100 p-0 m-0 d-flex align-items-center justify-content-center">
                          <div class="w-100">
                            <div class="input-field  mx-auto" id="inputemail">
                              <i class="fas fa-envelope"></i>
                              <input v-model="userRegister.email" required autocomplete="new-password" type="email" placeholder="Email" />
                            </div>
                            <div class="invalid-feedback text-center">
                                Email incorrecto
                            </div>
                          </div>
                        </div>
                        <div class="w-100 p-0 m-0 d-flex align-items-center justify-content-center">
                          <div class="w-100">
                            <div class="input-field  mx-auto" id="inputphone">
                              <i class="fas fa-phone"></i>
                              <input v-model="userRegister.phone_number" @input="ComprobarPhone()" required autocomplete="new-password" type="phone" placeholder="Telefono" />
                            </div>
                            <div class="invalid-feedback text-center">
                                Telefono incorrecto
                            </div>
                          </div>
                        </div>
                        <div class="w-100 p-0 m-0 d-flex align-items-center justify-content-center">
                          <div class="w-100">
                            <div class="input-field  mx-auto" id="inputpass">
                              <i class="fas fa-lock"></i>
                              <input v-model="userRegister.password" required @input="ComprobarPass()" autocomplete="new-password" type="password" placeholder="Contraseña" />
                            </div>
                            <div class="invalid-feedback text-center">
                                Al menos 8 caracteres
                            </div>
                          </div>
                        </div>
                        <input type="submit" class="btn" value="Registrarse" />
                      </form>
                    </div>
                    <div class="col-lg-6 pt-5">
                      <form @submit.prevent="IniciarSesion()" class="sign-in-form px-3">
                        <h2 class="title">Inicia Sesion</h2>
                        <div class="input-field">
                          <i class="fas fa-user"></i>
                          <input v-model="userLogin.username" required type="text" placeholder="Usuario" />
                        </div>
                        <div class="input-field" id="inputLoginFeedback">
                          <i class="fas fa-lock"></i>
                          <input v-model="userLogin.password" type="password" required autocomplete="new-password" placeholder="Contraseña" />
                        </div>
                        <div class="invalid-feedback text-center">
                            Datos Incorrectos
                        </div>
                        <input type="submit" value="Login" class="btn solid" />
                      </form>
                    </div>
                  </div>
                  <div class="w-100 px-4 row mx-0" v-else>
                    <p class="text-center">Ya puedes publicar tu plaza</p>
                  </div>
                  <div class="m-0 d-flex mt-4">
                    <a class="mx-auto py-2 px-4 rounded-3 post_button_disabled" id="botonPublicar" v-if="!isLoggedIn">Publicar</a>
                    <a class="mx-auto py-2 px-4 rounded-3 post_button" @click="Publicar" id="botonPublicar" v-else>Publicar</a>
                  </div>
                </div>
              </v-card>
            </div>
          </div>
        </transition>
        <p class="text-end pe-4 contadorPasos">{{ currentStep }}/{{items.length}}</p>
        </v-stepper>
      </div>
      <spinner-component id="spinner" class="d-none"></spinner-component>
      <modal-component ref="errorModal"></modal-component>
    </div>
</template>

<script>
import { VStepper } from 'vuetify/labs/VStepper'
import { mapState } from 'vuex';
import AddressBrowserComponent from '../components/AddressBrowserComponent.vue'
import TimeComponent from '../components/TimeComponent.vue'
import SliderPriceComponent from '../components/SliderPriceComponent.vue'
import InputCar from '../components/InputCarComponent.vue'
import L from 'leaflet';
import 'leaflet.markercluster';
import axios from 'axios';
import SpinnerComponent from '../components/SpinnerComponent.vue'
import ModalComponent from '../components/ModalComponent.vue'
import { v4 as uuidv4 } from 'uuid';
//import router from '@/router';

export default {
  name: 'SubirPlaza',
  data() {
    return {
      userLogin:{
        username: "",
        password: ""
      },
      userRegister:{
        username: "",
        email: "",
        phone_number: "",
        password:"",
        first_name:"",
        last_name:"",
        id: uuidv4()
      },
      currentStep: 1,
      items: ['Explicación', 'Detalles', 'Caracteristicas'],
      map: {},
      fullDay : false,
      diasSemana: {
        Lunes: 0,
        Martes: 1,
        Miercoles: 2,
        Jueves: 3,
        Viernes: 4,
        Sabado: 5,
        Domingo: 6,
      },
      itemsDias: [
        'Lunes',
        'Martes',
        'Miercoles',
        'Jueves',
        'Viernes',
        'Sabado',
        'Domingo',
      ],
      select:[],
      plazaCreate:{
        id: uuidv4(),
        vehicle_type: "car",
        coordinates: {
          x: 0,
          y: 0
        },
        monthly_price: 0,
        direction: "",
        height: "Largo",
        width: "Ancho",
        availability: {
          monday: false,
          tuesday: false,
          wednesday: false,
          thursday: false,
          friday: false,
          saturday: false,
          sunday: false
        },
        services: {
          security: false,
          disabled_people_service: false,
          no_columns: false
        },
        start_hour: null,
        start_minute: null,
        end_hour: null,
        end_minute: null,
        description: "",
        attachments:[]
      },
      isCorrect: false,
      markers: L.markerClusterGroup(),
      bloqueado: false
    };
  },
  computed:{
      ...mapState(['isLoggedIn']),
      ...mapState(['token'])
  },
  watch: {
    fullDay(){
      let horas = {
        startTime: "00:00",
        endTime: "00:00"
      }
      this.$refs.refInputHours.CambiarHoras(horas)
      this.plazaCreate.start_hour = 0
      this.plazaCreate.start_minute = 0
      this.plazaCreate.end_hour = 0
      this.plazaCreate.end_minute = 0
    },
    currentStep(newValue, oldValue) {
      if(this.bloqueado){
        this.bloqueado = false
        return
      }

      var errores = []
      if(newValue > oldValue){
        switch(oldValue){
          case 2:
            if(this.plazaCreate.coordinates.x == 0 && this.plazaCreate.coordinates.y == 0){
              errores.push("inputDireccion")
            }else{
              var inputDireccion = document.getElementById("inputDireccion")
              if (inputDireccion.classList.contains("is-invalid")) {
                inputDireccion.classList.remove("is-invalid")
              }
            }
            if(Object.values(this.plazaCreate.availability).every(valor => valor === false)){
              errores.push("inputDias")
            }else{
              var inputDias = document.getElementById("inputDias")
              if (inputDias.classList.contains("is-invalid")) {
                inputDias.classList.remove("is-invalid")
              }
            }
            if(this.plazaCreate.start_hour == null || this.plazaCreate.end_hour == null){
              errores.push("inputHoras")
            }else{
              var inputHoras = document.getElementById("inputHoras")
              if (inputHoras.classList.contains("is-invalid")) {
                inputHoras.classList.remove("is-invalid")
              }
            }
            if(typeof this.plazaCreate.height != 'number' || typeof this.plazaCreate.width != 'number'){
              errores.push("inputTamaño")
            }else{
              var inputTamaño = document.getElementById("inputTamaño")
              if (inputTamaño.classList.contains("is-invalid")) {
                inputTamaño.classList.remove("is-invalid")
              }
            }
            break
            case 3:
              if(this.plazaCreate.monthly_price == 0){
                errores.push("inputPrecio")
              }else{
                var inputPrecio = document.getElementById("inputPrecio")
                if (inputPrecio.classList.contains("is-invalid")) {
                  inputPrecio.classList.remove("is-invalid")
                }
              }
        }
      }
      errores.forEach(error=>{
        var input = document.getElementById(error)
        if (!input.classList.contains("is-invalid")) {
          input.classList.add("is-invalid")
        }
      })

      if(errores.length > 0){
        this.bloqueado = true
        this.currentStep = oldValue
        return;
      }
      var vm = this;
      if(newValue == 3){
        setTimeout(function() {
          vm.$refs.sliderPrice.ChangePrecio(vm.plazaCreate.monthly_price)
        }, 100);
      }
      if(newValue == 2){
        setTimeout(function() {
          if(vm.plazaCreate.vehicle_type=="car"){
            vm.$refs.refInputCar.cambiarOption(document.getElementById("Coche"))
          }else{
            vm.$refs.refInputCar.cambiarOption(document.getElementById("Moto"))
          }
          vm.$refs.refInputAddress.CambiarDireccion(vm.plazaCreate.direction)
          let horas = {
            startTime: null,
            endTime: null
          }
          if(vm.plazaCreate.start_hour != null){
            horas.startTime = (vm.plazaCreate.start_hour < 10) ? "0" : ""
            horas.startTime += vm.plazaCreate.start_hour + ":"
            horas.startTime += (vm.plazaCreate.start_minute < 10) ? "0" : ""
            horas.startTime += vm.plazaCreate.start_minute
          }
          if(vm.plazaCreate.end_hour != null){
            horas.endTime = (vm.plazaCreate.end_hour < 10) ? "0" : ""
            horas.endTime += vm.plazaCreate.end_hour + ":"
            horas.endTime += (vm.plazaCreate.end_minute < 10) ? "0" : ""
            horas.endTime += vm.plazaCreate.end_minute
          }
          vm.$refs.refInputHours.CambiarHoras(horas)
          
          // Tu código aquí se ejecutará después de 1 segundo
          vm.map = L.map('map', {zoomControl: true,zoom:1,zoomAnimation:false,fadeAnimation:true,markerZoomAnimation:true, scrollWheelZoom: false, dragging: false}).setView([39.850931195377946, -3.1256103515625004], 5)
          
          L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
            maxZoom: 20,
            minZoom: 6
          }).addTo(vm.map);
          if(vm.plazaCreate.coordinates.x != 0 || vm.plazaCreate.coordinates.y != 0){
            vm.map.setView([vm.plazaCreate.coordinates.x, vm.plazaCreate.coordinates.y], 15)
            vm.MeterMarcador(vm.plazaCreate.coordinates)
          }
        }, 100);
          
      }      
    },
    isLoggedIn(newValue){
      if(newValue == false){
        var indice = this.items.indexOf("Login");
        if(indice == -1){
          this.items.push("Login")
        }else{
          this.items.splice(indice, 1)
          this.items.push("Login")
        }
      }
    }
  },
  components:{
    VStepper,
    AddressBrowserComponent,
    TimeComponent,
    InputCar,
    SliderPriceComponent,
    SpinnerComponent,
    ModalComponent
  },
  methods:{
    limitCharacters() {
      if (this.plazaCreate.description.length > 200) {
        this.plazaCreate.description = this.plazaCreate.description.substring(0, 200);
      }
    },
    onInput() {
      var arregloDias = {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false
      };

      for (const key in this.select) {
        var nombreDia = this.select[key]
        const numeroDia = this.diasSemana[nombreDia];
        arregloDias[Object.keys(arregloDias)[numeroDia]] = true;
      }
      this.plazaCreate.availability = arregloDias
    },
    getDataCar(data) {
      if(data){
        this.plazaCreate.vehicle_type = "car"
      }else{
        this.plazaCreate.vehicle_type = "motorbike"
      }
    },
    getDataPrecio(data) {
      this.plazaCreate.monthly_price = data
    },
    getDataBrowser(inputValue, centro, isCorrect){
      if(isCorrect){
        this.plazaCreate.direction = inputValue
        this.plazaCreate.coordinates.x = centro[1]
        this.plazaCreate.coordinates.y = centro[0]
        this.isCorrect = isCorrect
        this.map.setView([centro[1], centro[0]], 15)
        this.MeterMarcador(this.plazaCreate.coordinates)
      }
    },
    getDataHoras(data) {
      let horas = null
      if(typeof data == 'string'){
        horas = JSON.parse(data)
      }else{
        horas = data
      }
      if(horas.startTime != null){
        this.plazaCreate.start_hour = parseInt(horas.startTime.split(":")[0])
        this.plazaCreate.start_minute = parseInt(horas.startTime.split(":")[1])
      }
      if(horas.endTime != null){
        this.plazaCreate.end_hour = parseInt(horas.endTime.split(":")[0])
        this.plazaCreate.end_minute = parseInt(horas.endTime.split(":")[1])
      }
      this.fullDay = false
    },
    MeterMarcador(centro){
      var divIcon = L.divIcon({
            // Establece el contenido HTML del icono
            html: '<div class="marcador"><p style="font-size:0.8rem">TU PLAZA</p></div><div class="flecha-down mx-auto"></div>',
            iconSize: [38, 40], // size of the icon
            shadowSize: [50, 64], // size of the shadow
            iconAnchor: [22, 40], // point of the icon which will correspond to marker's location
            popupAnchor: [-3, -76]
          });
      var marker = L.marker([centro.x, centro.y], {icon : divIcon});
      this.markers.clearLayers();
    
      this.map.addLayer(this.markers);
      this.markers.addLayer(marker);

      var popup = L.popup({ className: 'popup' });

      marker.bindPopup(popup);
    },
    handleImageUpload(event) {
      const files = event.target.files;
      const totalImages = files.length;

      if (files && totalImages > 0) {
        for (const file of files) {
          if (file.type.startsWith('image/')) {
            const reader = new FileReader();

            reader.onload = (e) => {
              const imageBlob = e.target.result;
              this.plazaCreate.attachments.push(imageBlob);
            };
            reader.readAsDataURL(file);
          }
        }
      }
    },
    removeImage(index){
      this.plazaCreate.attachments.splice(index, 1);
    },
    async IniciarSesion(){
      var spinner = document.getElementById("spinner")
      spinner.classList.remove("d-none")
      
      const apiUrl = 'https://api.apparca.es/v1/api/token/pair';
      axios.post(apiUrl, this.userLogin)
      .then(response => {
        this.$store.dispatch('setToken', response.data);
        spinner.classList.add("d-none")
      })
      .catch(error => {
        console.error(error);
        spinner.classList.add("d-none")
        var input = document.getElementById("inputLoginFeedback")
        input.classList += " is-invalid"
      });
    },
    async Registrarse(){
      var spinner = document.getElementById("spinner")
      spinner.classList.remove("d-none")
      let errores = 0
      
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      var inputEmail = document.getElementById("inputemail")
      if (emailRegex.test(this.userRegister.email)) {
        inputEmail.classList.remove("is-invalid")
      } else {
        errores++
        inputEmail.classList.add("is-invalid")
      }

      if(this.userRegister.password.length < 8){
        errores++
      }

      var inputPhone = document.getElementById("inputphone")
      const regex = /^\d{9}$/
      if (regex.test(this.userRegister.phone_number)) {
        inputPhone.classList.remove("is-invalid")
      } else {
        inputPhone.classList.add("is-invalid")
        errores++
      }

      if(errores == 0){
        let nombre = this.userRegister.first_name.split(" ")
        this.userRegister.first_name = nombre[0]
        this.userRegister.last_name = nombre.slice(1).join(' ');
        
        const apiUrl = 'https://api.apparca.es/v1/api/users';
        axios.post(apiUrl, this.userRegister)
        .then(response => {
          this.$store.dispatch('setToken', response.data);          
          spinner.classList.add("d-none")
        })
        .catch(error => {
          console.error(error);
          spinner.classList.add("d-none")
          var inputUser = document.getElementById("inputuser")
          inputUser.classList.add("is-invalid")
        });
      }else{
        spinner.classList.add("d-none")
      }
    },
    Publicar(){
      if(this.plazaCreate.monthly_price == 0){
        var inputPrecio = document.getElementById("inputPrecio")
        if (!inputPrecio.classList.contains("is-invalid")) {
          inputPrecio.classList.add("is-invalid")
        }
        return
      }
      var paso = this.currentStep
      paso++
      var vm = this
      
      setTimeout(function() {
        if(paso == 4 || vm.currentStep == 4){
          var spinner = document.getElementById("spinner")
          spinner.classList.remove("d-none")
          const apiUrl = 'https://api.apparca.es/v1/api/parking_lots';
          const headers = {
            Authorization: `Bearer ${vm.token}`,
          };
          axios.post(apiUrl, vm.plazaCreate, { headers })
          .then(() => {
            spinner.classList.add("d-none")
            //router.push({ name: 'MisPlazas' });
            vm.$refs.errorModal.error = false
            vm.$refs.errorModal.Abrir()
            spinner.classList.add("d-none")
          })
          .catch(error => {
            console.error(error);
            vm.$refs.errorModal.error = true
            vm.$refs.errorModal.Abrir()
            spinner.classList.add("d-none")
          });
        }
      }, 50);
    },
    ComprobarPass(){
      var input = document.getElementById("inputpass")
      if(this.userRegister.password.length <= 7){
        input.classList.add("is-invalid")
      }else{
        input.classList.remove("is-invalid")
      }
    },
    ComprobarPhone(){
      var input = document.getElementById("inputphone")
      const regex = /^\d{9}$/
      if (regex.test(this.userRegister.phone_number)) {
        input.classList.remove("is-invalid")
      } else {
        input.classList.add("is-invalid")
      }
    }
  },
  mounted(){
    if(!this.isLoggedIn){
      this.items.push("Login")
    }
  }
}

</script>

<style>
.fondo{
  background-image: url(/src/assets/fondoCreate.png);
  background-size: cover;
  width: 100%;
  height: 100%;
  background-position: center;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.formulario{
  margin-left: 15%;
  width: 70%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 15px #fa8e00;
}

.imagenesSubidas{
  height: 150px;
  width: auto;
}

.post_button{
  background-color: #205760;
  text-decoration: none;
  color:white;
  transition: 0.4s;
  cursor: pointer;
  border: 1px solid #205760;
}

.post_button_disabled{
  background-color: #f8f8f8;
  text-decoration: none;
  color:#c5c5c5;
  cursor: pointer;
}

.post_button:hover{
  background-color: white;
  color:#1a484f;
}

.parent-div {
  position: relative;
}

.DeleteIcon{
  position: absolute;
  top:-10;
  right: -15;
}

.v-window{
  margin-top: 0px;
}

.step{
  min-height: 60vh !important;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.contadorPasos{
  display: none;
}

.registroCreate{
  border-right: 3px solid #205760;
}


@media screen and (max-width: 1200px) {
  .formulario{
    margin-left: 10%;
    width: 80%;
  }
}

@media screen and (max-width: 992px) {
  .formulario{
    margin-left: 5%;
    width: 90%;
  }
  .imagenesSubidas{
    height: 100px;
    width: auto;
  }
  .registroCreate{
    border-right: 0px solid #205760;
  }
}

@media screen and (max-width: 700px) {
  .v-stepper-header .v-stepper-item:not(.v-stepper-item--selected) {
    display: none;
  }

  .v-divider{
    display: none;
  }

  .contadorPasos{
    display: block;
  }
}
</style>
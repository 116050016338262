import { createWebHistory, createRouter } from "vue-router";
import Home from "../views/Home.vue";
import VistaMapa from "../views/VistaMapa.vue";
import VistaLista from "../views/VistaLista.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import SubirPlaza from "../views/SubirPlaza.vue";
import EditarPlaza from "../views/EditarPlaza.vue";
import DetailsPlaza from "../views/DetailsPlaza.vue";
import EditarCuenta from "../views/EditarCuenta.vue";
import MisPlazas from "../views/MisPlazas.vue";
import MisFavoritos from "../views/MisFavoritos.vue";
import PoliticaPrivacidad from "../views/PrivacyPolicy.vue";
import Terminos from "../views/TerminosCondiciones.vue";
import CookiesPage from "../views/CookiesPage.vue";
import SobreApparca from "../views/SobreApparca.vue";
import PruebaLogin from "../views/proveLogin.vue";
import store from '../store';


const routes = [
  {
    path: "/", redirect: "/Home",
  },
  {
    path: "/Home",
    name: "Home",
    component: Home,
    meta: { showHeader: false },
  },
  {
    path: "/VistaMapa",
    name: "VistaMapa",
    component: VistaMapa,
    meta: { showHeader: true },
  },
  {
    path: "/MisPlazas",
    name: "MisPlazas",
    component: MisPlazas,
    meta: { showHeader: true },
  },
  {
    path: "/parking_lot/:numero",
    name: "parking_lot",
    component: DetailsPlaza,
    meta: { showHeader: true },
  },
  {
    path: "/VistaLista",
    name: "VistaLista",
    component: VistaLista,
    meta: { showHeader: true, isFavoritos: false },
  },
  {
    path: "/favorites",
    name: "FavoritosDons",
    component: MisFavoritos,
    meta: { showHeader: true },
  },
  {
    path: "/SubirPlaza",
    name: "SubirPlaza",
    component: SubirPlaza,
    meta: { showHeader: true },
  },
  {
    path: "/EditarPlaza/:numero",
    name: "EditarPlaza",
    component: EditarPlaza,
    meta: { showHeader: true },
  },
  {
    path: "/EditarCuenta",
    name: "EditarCuenta",
    component: EditarCuenta,
    meta: { showHeader: true }
  },
  {
    path: "/Login",
    name: "Login",
    component: Login,
    meta: { showHeader: true, isLogin:true },
  },
  {
    path: "/Register",
    name: "Register",
    component: Register,
    meta: { showHeader: true, isLogin:false },
  },
  {
    path: "/PoliticaPrivacidad",
    name: "PoliticaPrivacidad",
    component: PoliticaPrivacidad,
    meta: { showHeader: true},
  },
  {
    path: "/Terminos",
    name: "Terminos",
    component: Terminos,
    meta: { showHeader: true},
  },
  {
    path: "/Cookies",
    name: "Cookies",
    component: CookiesPage,
    meta: { showHeader: true},
  },
  {
    path: "/SobreApparca",
    name: "SobreApparca",
    component: SobreApparca,
    meta: { showHeader: true},
  },
  {
    path: "/prueba",
    name: "Prueba",
    component: PruebaLogin,
    meta: { showHeader: true},
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  await store.dispatch('initialice');
  window.scrollTo(0, 0);
  next();
});

export default router;
import { createStore } from 'vuex';
import axios from 'axios';

async function ComprobarToken(commit) {
  const tokenString = localStorage.getItem('token');
  let token;

  if (tokenString && tokenString !== "null") {
    token = JSON.parse(tokenString);

    try {
      const verify = {
        token: token
      };
      const apiUrl = 'https://api.apparca.es/v1/api/token/verify';
      await axios.post(apiUrl, verify);
      ActualizarFavs(token)
      return true;
    } catch (error) {
      const refresh = JSON.parse(localStorage.getItem('refresh'));

      if (refresh !== null) {
        const refreshObj = {
          refresh: refresh
        };
        const apiRefresh = 'https://api.apparca.es/v1/api/token/refresh';

        try {
          const response = await axios.post(apiRefresh, refreshObj);
          commit('login', response.data);
          ActualizarFavs(response.data.access)
          return true;
        } catch (error) {
          localStorage.setItem('refresh', null);
          localStorage.setItem('token', null);
          console.error(error);
          return false;
        }
      } else {
        console.error('No token found in localStorage');
        localStorage.setItem('refresh', null);
        localStorage.setItem('token', null);
        return false;
      }
    }
  } else {
    console.error('No token found in localStorage');
    localStorage.setItem('refresh', null);
    localStorage.setItem('token', null);
    return false;
  }
}

function ActualizarFavs(token){
  const apiUrl = 'https://api.apparca.es/v1/api/favorites';
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let restaurarFavs = []
    axios.get(apiUrl, { headers })
    .then(response => {
      let plazas = response.data.items
      plazas.forEach(plaza => {
        restaurarFavs.push(plaza.parking_lot.id)
      });
      localStorage.setItem('favoritos', JSON.stringify(restaurarFavs));
    })
    .catch(error => {
      console.error(error);
    });
}


const store = createStore({
  state: {
    token: JSON.parse(localStorage.getItem('token')),
    refresh: JSON.parse(localStorage.getItem('refresh')),
    isLoggedIn: false,
    isCar: true,
    direccion: "",
    coordenadas: null,
    horasHome: null,
    filtroStore: null
  },
  mutations: {
    login(state, data) {
      state.isLoggedIn = true;
      state.token = data.access;
      state.refresh = data.refresh
      localStorage.setItem('token', JSON.stringify(data.access))
      localStorage.setItem('refresh', JSON.stringify(data.refresh))
    },
    logout(state) {
      localStorage.setItem('refresh', null);
      localStorage.setItem('token', null);
      state.isLoggedIn = false;
    },
    pasarVariablesHome(state, data){
      state.isCar = data.isCar;
      state.direccion = data.direccion;
      state.coordenadas = data.coordenadas;
      state.horasHome = data.horas;
    },
    limpiar(state){
      state.filtroStore = null
    },
    PasarDatos(state, filtro){
      state.filtroStore = filtro
    },
    setLoggedIn(state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn;
    }
  },
  actions: {
    async initialice({ commit }) {
      const isLoggedIn = await ComprobarToken(commit);
      commit('setLoggedIn', isLoggedIn);
    },
    setToken({ commit }, data) {
      commit('login', data);
    },
    pasarVariablesHome({ commit }, { isCar, direccion, coordenadas, horas }) {
      commit('pasarVariablesHome', { isCar, direccion, coordenadas, horas });
    },
    limpiar({commit}){
      commit('limpiar')
    },
    PasarDatos({ commit }, filtro){
      commit('PasarDatos', filtro);
    },
    TokenBueno({ commit }){
      commit('TokenBueno');
    }
  }
});

export default store;

<template>
    <div class="modal-container" id="modal">
      <div class="bg-white rounded-4 px-2 pt-4" v-if="!error" style="max-width: 500px;">
        <h2 class="px-5 text-center">Plaza creada correctamente</h2>
        <div class="m-0 d-flex" >
          <img src="./../assets/iconoCorrecto.png" class="mx-auto" style="height: 200px;">
        </div>
        <p class="text-center px-3">Tu plaza se ha publicado correctamente, puedes visualizarla y editarla en tu cuenta</p>
        <div class="m-0 d-flex" >
          <router-link to="MisPlazas" class="mx-auto py-2 px-4 mb-4 mt-3 rounded-3 post_button" @click="Cerrar()">Ir a mis plazas</router-link>
        </div>
      </div>
      <div class="bg-white rounded-4 px-2" v-else style="max-width: 500px;">
        <div class="d-flex align-items-end justify-content-end">
          <p @click="Cerrar()" class="pt-3 pe-3" style="cursor: pointer;">X</p>
        </div>
        <h2 class="px-5 text-center">Error al crear la plaza!</h2>
        <div class="m-0 d-flex" >
          <img src="./../assets/errorIcon.png" class="mx-auto" style="height: 200px;">
        </div>
        <p class="text-center px-3">Revisa los datos introducidos y si el problema continua ponte en contacto con nostros: 632 16 15 37</p>
        <div class="m-0 d-flex" >
          <a class="mx-auto py-2 px-4 mb-4 mt-3 rounded-3 post_button" @click="Cerrar()">Intentalo de nuevo</a>
        </div>
      </div>
    </div>
  </template>

  <script>
  export default {
    name: "ModalComponent",
    data() {
      return {
        error: true,
      };
    },
    methods: {
      Cerrar() {
        var modal = document.getElementById("modal")
        modal.style.opacity = 0

        setTimeout(function () {
            modal.style.display = 'none';
        }, 500);
      },
      Abrir() {
        console.log("abriendo")
        var modal = document.getElementById("modal")
        modal.style.display = 'flex';
        
        modal.style.opacity = 100
      },
    }
  }
</script>
  
  <style>
  .modal-container {
    position: fixed;
    top: 0;
    transition: opacity 0.5s;
    left: 0;
    width: 100%;
    opacity: 0;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Fondo oscuro con opacidad */
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Asegura que esté por encima de otros elementos */
  }
  </style>
  
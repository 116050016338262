<template>
    <div class="container my-4">
        <h1>Términos y Condiciones de Uso de Apparca</h1>
    <p><strong>Fecha de entrada en vigencia:</strong> 23/10/2023</p>

    <h2>1. Aceptación de los Términos</h2>
    <p>Al utilizar los servicios de Apparca, usted acepta cumplir con estos términos y condiciones, así como con nuestra Política de Privacidad. Si no está de acuerdo con estos términos, por favor, no utilice la plataforma.</p>

    <h2>2. Uso de la Plataforma</h2>
    <h3>2.1. Elegibilidad</h3>
    <p>Solo las personas mayores de 18 años pueden utilizar Apparca. Al utilizar nuestros servicios, usted declara y garantiza que tiene al menos 18 años.</p>

    <h3>2.2. Registro</h3>
    <p>Puede ser necesario registrarse en la plataforma para acceder a ciertos servicios. Proporcione información precisa y actualizada al registrarse, y mantenga su información de contacto actualizada.</p>

    <h3>2.3. Publicación de Plazas de Garaje</h3>
    <p>Los propietarios pueden publicar sus plazas de garaje disponibles en Apparca. La información proporcionada debe ser precisa y completa.</p>

    <h3>2.4. Alquiler de Plazas de Garaje</h3>
    <p>Los usuarios interesados en alquilar plazas de garaje deben respetar las tarifas y condiciones establecidas por los propietarios de las plazas. Apparca no es parte de los acuerdos de alquiler y no se hace responsable de los mismos.</p>

    <h2>3. Obligaciones de los Usuarios</h2>
    <h3>3.1. Cumplimiento de la Ley</h3>
    <p>Los usuarios deben cumplir con todas las leyes y regulaciones aplicables al utilizar la plataforma.</p>

    <h3>3.2. Respeto a los Derechos de Propiedad</h3>
    <p>No debe copiar, distribuir ni utilizar información, imágenes u otros contenidos de la plataforma sin el permiso previo de los propietarios de esos derechos.</p>

    <h2>4. Responsabilidad de Apparca</h2>
    <h3>4.1. Limitación de Responsabilidad</h3>
    <p>Apparca no asume responsabilidad por cualquier daño o pérdida resultante del uso de la plataforma o de los acuerdos de alquiler entre usuarios.</p>

    <h2>5. Cancelación y Terminación</h2>
    <p>Apparca se reserva el derecho de suspender o cancelar su cuenta en caso de incumplimiento de estos términos y condiciones o de la legislación vigente.</p>

    <h2>6. Modificaciones de los Términos y Condiciones</h2>
    <p>Apparca puede modificar estos términos y condiciones en cualquier momento. Se notificarán los cambios a los usuarios y entrarán en vigencia en la fecha especificada.</p>

    <h2>7. Política de Privacidad</h2>
    <p>La Política de Privacidad de Apparca está disponible en la plataforma y se aplica al uso de sus servicios.</p>

    <h2>8. Ley Aplicable y Jurisdicción</h2>
    <p>Estos términos y condiciones se rigen por las leyes de España. Cualquier disputa se resolverá en los tribunales de España.</p>

    <p>Gracias por utilizar Apparca. Si tiene alguna pregunta o comentario sobre estos términos y condiciones, no dude en ponerse en contacto con nosotros a través de contacto@apparca.es</p>  
    </div>
  </template>
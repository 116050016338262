<template>
    <div class="fondo">
      <router-link to="/Favorites" class="btn bg-white p-2 position-absolute z-2 botonFavs" style="z-index: 100 !important; bottom: 6vh">
        <span class="fas fa-star text-center w-100 star favorito" style="font-size: 30px;" ></span>
      </router-link>
      <div class="formularioEdit pb-4" >
        <div v-if="isLoggedIn" class="edit">
          <div class="w-100 m-0">
            <div class="row m-0">
              <div class="col-lg-2 col-md-4 col-12 d-grid divIconUser" >
                <img src="./../assets/iconoUsuario.png" style="border-radius: 70px; background-color: rgb(240, 239, 239); padding: 10px;" class="mx-auto my-5 mb-4 userIcon">
              </div>
              <div class="col-lg-10 col-md-8 col-12 pt-3 row m-0">
                <div class="col-lg-6 d-flex align-items-end mt-3">
                  <v-text-field 
                    variant="underlined"
                    :rules="[rules.required]"
                    label="Usuario" v-model="user.username"
                    prepend-inner-icon="mdi-account" 
                  ></v-text-field>
                </div>
                <div class="col-lg-6 d-flex align-items-end mt-3">
                  <v-text-field
                  variant="underlined"
                  :rules="[rules.required, rules.email]"
                  label="Correo" v-model="user.email"
                  prepend-inner-icon="mdi-email" 
                ></v-text-field>
                </div>
                <div class="col-lg-6 d-flex align-items-end mt-3">
                  <v-text-field 
                    variant="underlined"
                    :rules="[rules.required]"
                    label="Nombre" v-model="user.first_name"
                    prepend-inner-icon="mdi-account" 
                  ></v-text-field>
                </div>
                <div class="col-lg-6 d-flex align-items-end mt-3">
                  <v-text-field
                  variant="underlined"
                  :rules="[rules.required]"
                  label="Apellidos" v-model="user.last_name"
                  prepend-inner-icon="mdi-account" autocomplete="new-password"
                ></v-text-field>
                </div>
              </div>
            </div>
          </div>
          <div class="row m-0 mt-md-4">
            <div class="col-12  py-1 row m-0 ">
              <div class="col-lg-4 d-flex align-items-center ">
                <v-text-field
                variant="underlined"
                label="Contraseña" v-model="user.password"
                prepend-inner-icon="mdi-lock" 
                :type="show1 ? 'text' : 'password'"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show1 = !show1" autocomplete="new-password"
              ></v-text-field>
              </div>
              <div class="col-lg-4 d-flex align-items-center">
                <v-text-field
                variant="underlined"
                :rules="[rules.password]"
                label="Repite tu contraseña" v-model="confirmPassword"
                prepend-inner-icon="mdi-lock" 
                :type="show2 ? 'text' : 'password'"
                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show2 = !show2"
              ></v-text-field>
              </div>
              <div class="col-lg-4 d-flex align-items-center">
                <v-text-field
                variant="underlined"
                :rules="[rules.phone]"
                label="Telefono" v-model="user.phone_number"
                prepend-inner-icon="mdi-phone"
              ></v-text-field>
              </div>
            </div>
          </div>
          <div class="m-0 d-flex">
            <a class="mx-auto py-2 px-4 rounded-3 post_button" @click="Guardar">Guardar</a>
          </div>
          <div class="d-flex align-items-end justify-end">
            <router-link to="MisPlazas" class="py-2 px-4 me-4 mt-4 rounded-3 post_button">Mis Plazas
              <i class="bi bi-car-front-fill ms-2"></i></router-link>
          </div>
        </div>
        <div v-else>
          <div style="height: 60vh" class="d-flex" >
            <img src="./../assets/denegacion.svg" class="h-100 mx-auto">
          </div>
          <div class="d-flex">
            <span class="mx-auto p-3 botonLogin text-white rounded mb-4">
              <router-link to="Login" class="text-decoration-none text-white">Inicia Sesion</router-link> o 
              <router-link to="Register" class="text-decoration-none text-white">Registrate</router-link>
            </span>
          </div>
        </div>
      </div>
      <spinner-component id="spinner" class="d-none"></spinner-component>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import axios from 'axios';
import SpinnerComponent from '../components/SpinnerComponent.vue'

export default {
  name: 'EditarCuenta',
  data() {
    return {
      show1: false,
      show2: false,
      user:{},
      confirmPassword: "",
      rules: {
          required: value => !!value || 'Obligatorio',
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Correo invalido'
          },
          phone: value => {
            const pattern = /^\d{9}$/
            return pattern.test(value) || 'Telefono invalido'
          },
          password: value => {
            return value == this.user.password || 'Las contraseñas no coinciden'
          },
      }
    };
  },
  computed:{
      ...mapState(['isLoggedIn']),
      ...mapState(['token'])
  },
  watch: {
    
  },
  components:{
    SpinnerComponent
  },
  methods:{
    Guardar(){
      var spinner = document.getElementById("spinner")
      spinner.classList.remove("d-none")

      let errors = 0
      if(this.user.username.length == 0){
        errors++
      }
      if(this.user.first_name.length == 0 || this.user.last_name.length == 0){
        errors++
      }
      if(this.user.password != this.confirmPassword){
        errors++
      }
      let regex = /^\d{9}$/;
      if (!regex.test(this.user.phone_number)) {
        errors++
      }
      regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!regex.test(this.user.email)) {
        errors++
      }

      if (errors==0) {
        const apiUrl = 'https://api.apparca.es/v1/api/users/me';
        const headers = {
          Authorization: `Bearer ${this.token}`,
        };
        axios.put(apiUrl, this.user, { headers })
        .then(() => {
          spinner.classList.add("d-none")
        })
        .catch(error => {
          console.error(error);
          spinner.classList.add("d-none")
        });
      }else{
        spinner.classList.add("d-none")
      }
    }
  },
  mounted(){
    if(this.isLoggedIn){
      var spinner = document.getElementById("spinner")
      spinner.classList.remove("d-none")

      const apiUrl = 'https://api.apparca.es/v1/api/users/me';
      const headers = {
        Authorization: `Bearer ${this.token}`,
      };
      axios.get(apiUrl, { headers })
      .then(response => {
        this.user = response.data
        delete this.user.updated_at
        delete this.user.created_at
        delete this.user.id
        this.user.password = ""
        spinner.classList.add("d-none")
      })
      .catch(error => {
        console.error(error);
        spinner.classList.add("d-none")
      });
    }
  }
}

</script>

<style>
.fondo{
  background-image: url(/src/assets/fondoCreate.png);
  background-size: cover;
  width: 100%;
  height: 100%;
  background-position: center;
  padding-top: 7rem;
  padding-bottom: 5rem;
}
.formularioEdit{
  margin-left: 25%;
  width: 50%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 15px #fa8e00;
}


.botonLogin{
  background-color: #205760;
  transition: 0.5s;
}

.botonLogin:hover{
  cursor: pointer;
  background-color: #fa8e00;
}

.v-icon{
  color: #205760 !important;;
}

.userIcon{
  width: 75%;
}

.divIconUser{
  border-right: 2px solid #205760;
  border-bottom: 2px solid #205760;
  border-radius: 0px 0px 15px 0px;
}

@media screen and (max-width: 1400px) {
  .formularioEdit{
    margin-left: 20%;
    width: 60%;
  }
}

@media screen and (max-width: 1200px) {
  .formularioEdit{
    margin-left: 15%;
    width: 70%;
  }
}

@media screen and (max-width: 768px) {
  .userIcon{
    width: 20%;
  }
  .divIconUser{
    border: 0px;
  }
  .formularioEdit{
    margin-left: 10%;
    width: 80%;
  }
}

@media screen and (max-width: 576px) {
  .formularioEdit{
    margin-left: 5%;
    width: 90%;
  }
}

</style>
<template>
    <div>
      <div @click="login" class="btn btn-light border m-5">
        <img style="height: 25px;" src="./../assets/googleIcon.png" />
        <span class="ms-3">Inicia con Google</span>
        </div>
      <div v-if="userDetails">
        <h2>User Details</h2>
        <p>Name: {{ userDetails.name }}</p>
        <p>Email: {{ userDetails.email }}</p>
        <p>Profile Picture: <img :src="userDetails.picture" alt="Profile Picture"></p>
      </div>
      <img src="../assets/Bienvenida3.png">
      <img src="../assets/Bienvenida4.png">
    </div>
  </template>
  
  <script>
  import { googleSdkLoaded } from "vue3-google-login";
  import axios from "axios";
  
  export default {
    name: "YourComponent",
    data() {
      return {
        userDetails: null,
      };
    },
    methods: {
      login() {
        googleSdkLoaded(google => {
          google.accounts.oauth2
            .initCodeClient({
              client_id:
                "720885077532-cql4snp9ppsfh860a5b7alnchv28qg2a.apps.googleusercontent.com",
              scope: "email",
              redirect_uri: "http://localhost:4000/auth/callback",
              callback: response => {
                if (response.code) {
                    // Obtén el código de autorización
                    const code = response.code;
                    
                    // Intercambia el código por un token de acceso
                    this.intercambiarCodigoPorToken(code);
                }
              }
            })
            .requestCode();
        });
      },
      intercambiarCodigoPorToken(code) {
        console.log("Aqui llego", code)
        const data = new URLSearchParams();
        data.append('code', code);
        data.append('client_id', '720885077532-cql4snp9ppsfh860a5b7alnchv28qg2a.apps.googleusercontent.com');
        data.append('client_secret', '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');
        data.append('redirect_uri', 'http://localhost:8080');
        data.append('grant_type', 'authorization_code');

        axios
            .post('https://oauth2.googleapis.com/token', data)
            .then(response => {
            const accessToken = response.data.access_token;
            // Ahora que tienes el token de acceso, puedes obtener la información del usuario.
            this.obtenerInformacionDelUsuario(accessToken);
            })
            .catch(error => {
            console.error('Error al intercambiar el código por el token de acceso:', error);
            });
        },
      obtenerInformacionDelUsuario(accessToken) {
        console.log("Aqui tambien llego")
        axios
            .get('https://www.googleapis.com/oauth2/v1/userinfo', {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
            })
            .then(response => {
                console.log(response.data)
            const userEmail = response.data.email;
            // Aquí puedes utilizar userEmail para registrar al usuario en tu aplicación web.
            console.log('Correo del usuario:', userEmail);
            })
            .catch(error => {
            console.error('Error al obtener la información del usuario:', error);
            });
        }
    }
  };
  </script>
  <style>

</style>
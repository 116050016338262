<template>
  <router-link to="/Favorites" class="btn bg-white p-2 position-fixed z-2 botonFavs" style="bottom: 6vh">
    <span class="fas fa-star text-center w-100 star favorito" style="font-size: 30px;" ></span>
  </router-link>
  <div class="row container-lg mx-auto p-0 m-0" style="min-height: 90vh;">
    <filtros-component :numeroFiltros="numeroFiltros" ref="refFiltro" v-if="this.screenWidth >= 992" @PasarDatos="PasarDatos" @limpiarFiltro="limpiarFiltro" @getDataCar="getDataCar" @getDataAncho="getDataAncho" @getDataAlto="getDataAlto" @getDataServicio="getDataServicio" @getDataPrecio="getDataPrecio" @getDataHoras="getDataHoras" @getDataDias="getDataDias" @getDataBrowser="getDataBrowser" class="filtroVista"></filtros-component>
    <div class="col-lg-9 p-0 pt-lg-5 mt-lg-5 mt-3">
      <div class="col-12 d-lg-none mb-4 pb-3 filtroMovil">
        <div class="row m-0">
          <div class="p-0 col-6">
            <a class="text-center mb-0 fw-bold nav-link" data-bs-toggle="collapse" href="#collapseExample" role="button"
              aria-expanded="false" aria-controls="collapseExample" style="color:#205760; font-size: 1.1rem;">Filtros
              <span style="background-color: #fa8e00;" class="rounded-5 px-2">{{numeroFiltros}}</span></a>
          </div>
          <div class="col-6 p-0">
            <p class="text-center mb-0 fw-bold" @click="PasarDatos" style="color:#205760; font-size: 1.1rem;">Vista mapa</p>
          </div>
          <div class="collapse" id="collapseExample">
            <filtros-component :numeroFiltros="numeroFiltros" ref="refFiltro" v-if="this.screenWidth < 992" @PasarDatos="PasarDatos" @getDataCar="getDataCar" @getDataAncho="getDataAncho" @limpiarFiltro="limpiarFiltro" @getDataAlto="getDataAlto" @getDataServicio="getDataServicio" @getDataPrecio="getDataPrecio" @getDataHoras="getDataHoras" @getDataDias="getDataDias" @getDataBrowser="getDataBrowser" ></filtros-component>
          </div>
        </div>
      </div>
      <div class="col-11 mx-auto">
        <div class="dropdown custom-dropdown mb-4">
          <a class=" dropdown-toggle" style="color: #205760; text-decoration: none;" type="button"
            id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span class="fw-semibold">Ordenar:</span> {{this.ordenTextos[this.orden]}}
          </a>
          <ul class="dropdown-menu mt-2" aria-labelledby="dropdownMenuButton" style="cursor: pointer;">
            <li><a class="dropdown-item" @click="CambiarOrden(0)">Más cercanos</a></li>
            <li><a class="dropdown-item" @click="CambiarOrden(1)">Precio menor</a></li>
            <li><a class="dropdown-item" @click="CambiarOrden(2)">Precio mayor</a></li>
          </ul>
        </div>

        <div v-for="(item, index) in this.plazas" :key="index" class="row m-0 rounded-4 plazaItem mb-3 overflow-hidden">
          <a class="col-2 p-0 d-md-block d-none" :href="'/parking_lot/' + item.id">
            <img :src=item.preview class="w-100" style="max-height: 150px ;">
          </a>
          <div class="col-md-6">
            <div class="row h-100 align-items-center">
              <a :href="'/parking_lot/' + item.id" class="fw-bold mb-1 textoPrimario text-decoration-none">{{item.direccion}}</a>
              <div class="col-4">
                <p class="mb-1 fw-semibold textoPrimario">Horario</p>
                <p class="mb-0" style="color: #888888;">{{formatTime(item.horas.startTime)}} - {{formatTime(item.horas.endTime)}}</p>
              </div>
              <div class="col-6">
                <p class="mb-1 fw-semibold textoPrimario">Tamaño</p>
                <p class="mb-0" style="color: #888888;"><img src="../../src/assets/iconoAltura.png" height="20">{{item.ancho}} x {{item.largo}} cm</p>
              </div>
              <span @click="this.Fav(item.id)" v-if="favoritos.includes(item.id)" class="fas fa-star col-2 text-end star favorito" ></span>
              <span @click="this.Fav(item.id)" v-else class="fas fa-star star text-end col-2" ></span>
            </div>
          </div>
          <div class="col-md-2 col-6 d-flex align-items-center justify-content-center">
            <div>
              <p class="fw-semibold mb-3" style="color: #133C44;">Servicios</p>
              <div class="mx-auto px-3 pb-2" style="display: flex; justify-content: center;">
                  <img v-if="item.servicios.security" class="icono me-3" src="./../assets/iconoSeguridad.png">
                  <img v-if="item.servicios.disabled_people_service" class="icono me-3" src="./../assets/iconoAccesoAdaptado.png">
                  <img v-if="item.servicios.no_columns" class="icono" src="./../assets/iconoColumns.png">
              </div>
            </div>
          </div>
          <div class="col-md-2 col-6 d-flex align-items-center " style="background-color: #CFE2E4;">
            <div>
              <p class="fw-semibold mb-0 " style="color: #133C44;">Precio</p>
              <p class="fw-bold mb-0 fs-3 text-center" style="color: #000000;">{{item.precio}}€</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <spinner-component id="spinner" class="d-none"></spinner-component>
  </div>
</template>

<script>
import FiltrosComponent from '../components/FiltrosComponent.vue'
import { mapState } from 'vuex';
import router from '@/router';
import axios from 'axios';
import SpinnerComponent from '../components/SpinnerComponent.vue'

export default {
  name: 'VistaLista',
  data: function() {
      return {
        filtro:{
          inputValue: "",
          latitud: "38.3451700",
          longitud: "-0.4814900",
          horas: null,
          isCoche: true,
          precio: 200,
          disponibilidad:[false, false, false, false, false, false, false],
          servicios: {
            security: false,
            disabled_people_service: false,
            no_columns: false
          },
          ancho: null,
          alto: null,
        },
        orden: 0,
        ordenTextos: ["Más Cercanos", "Precio menor", "Precio mayor"],
        screenWidth: window.innerWidth,
        favoritos: JSON.parse(localStorage.getItem('favoritos')) != null ? JSON.parse(localStorage.getItem('favoritos')) : [],
        plazas: [],
        llamadaDoble: false,
        numeroFiltros: 0
      }
  },
  computed:{
      ...mapState(['filtroStore'])
  },
  components:{
    FiltrosComponent,
    SpinnerComponent
  },
  methods:{
    getDataCar(data) {
      this.filtro.isCoche = data
      if(!this.llamadaDoble){
        this.actualizarResultados()
      }else{
        this.llamadaDoble = false
      }
    },
    getDataHoras(data) {
      this.filtro.horas = JSON.parse(data)
      this.actualizarResultados()
    },
    getDataPrecio(data) {
      this.filtro.precio = data
      this.actualizarResultados()
    },
    getDataBrowser(inputValue, centro, isCorrect){
      if(isCorrect){
        this.filtro.inputValue = inputValue
        this.filtro.latitud = centro[1]
        this.filtro.longitud = centro[0]
        this.isCorrect = isCorrect

        this.actualizarResultados()
      }
    },
    getDataServicio(servicio, value){
      const propiedades = Object.keys(this.filtro.servicios);

      this.filtro.servicios[propiedades[servicio]] = value;
      this.actualizarResultados()
    },
    getDataAncho(data){
      this.filtro.ancho = data
      this.actualizarResultados()
    },
    getDataAlto(data){
      this.filtro.alto = data
      this.actualizarResultados()
    },
    getDataDias(data){
      this.filtro.disponibilidad = data
      this.actualizarResultados()
    },
    limpiarFiltro(){
      this.filtro = {
        inputValue: "",
        latitud: "38.3451700",
        longitud: "-0.4814900",
        horas: null,
        isCoche: true,
        precio: 100,
        disponibilidad:[false, false, false, false, false, false, false],
        servicios: {
          security: false,
          disabled_people_service: false,
          no_columns: false
        },
        ancho: null,
        alto: null,
      }
      this.actualizarNumerofiltros()
    },
    getFilter() {
      let filtro = ""; 
      filtro += this.filtro.isCoche ? "&vehicle_type=car" : "&vehicle_type=motorbike" 
      filtro += this.filtro.ancho !== null && this.filtro.ancho !== "" ? ("&min_width=" + this.filtro.ancho) : "" 
      filtro += this.filtro.alto !== null && this.filtro.alto !== "" ? ("&min_height=" + this.filtro.alto) : ""
      
      filtro += "&max_monthly_price="+ this.filtro.precio

      filtro += "&latitude=" + this.filtro.latitud + "&longitude=" + this.filtro.longitud
      filtro += "&order=" + this.orden

      let days = []
      if (this.filtro.disponibilidad[0]){ days.push("mon") }
      if (this.filtro.disponibilidad[1]){ days.push("tue") }
      if (this.filtro.disponibilidad[2]){ days.push("wed") }
      if (this.filtro.disponibilidad[3]){ days.push("thu") }
      if (this.filtro.disponibilidad[4]){ days.push("fri") }
      if (this.filtro.disponibilidad[5]){ days.push("sat") }
      if (this.filtro.disponibilidad[6]){ days.push("sun") }

      if (days.length > 0) {
        filtro += "&availability=" + days.toString()
      }

      if(this.filtro.horas != null){
        if(this.filtro.horas.startTime != ''){
          filtro += "&start_hour=" + this.filtro.horas.startTime.split(":")[0]
          filtro += "&start_minute=" + this.filtro.horas.startTime.split(":")[1]
        }
        if(this.filtro.horas.endTime != ''){
          filtro += "&end_hour=" + this.filtro.horas.endTime.split(":")[0]
          filtro += "&end_minute=" + this.filtro.horas.endTime.split(":")[1]
        }
      }

      filtro += this.filtro.servicios.security ? "&security_service=true" : ""
      filtro += this.filtro.servicios.disabled_people_service ? "&disabled_people_service=true" : ""
      filtro += this.filtro.servicios.no_columns ? "&no_columns_service=true" : ""
      
      return filtro
    },
    actualizarNumerofiltros(){
      this.numeroFiltros = 0;
      (this.filtro.inputValue != "") ? this.numeroFiltros++ : undefined;
      (this.filtro.horas != null) ? this.numeroFiltros++ : undefined;
      (this.filtro.isCoche !== true) ? this.numeroFiltros++ : undefined;
      
      (this.filtro.precio != 100) ? this.numeroFiltros++ : undefined;
      let todosFalse = true;

      for (let i = 0; i < this.filtro.disponibilidad.length; i++) {
        if (this.filtro.disponibilidad[i] !== false) {
          todosFalse = false;
          break; // Sal del bucle tan pronto como encuentres un valor distinto de false
        }
      }
      (!todosFalse) ? this.numeroFiltros++ : undefined;
      (this.filtro.servicios.security != false || this.filtro.servicios.disabled_people_service != false || this.filtro.servicios.no_columns != false) ? this.numeroFiltros++ : undefined;
      (this.filtro.ancho !== null && this.filtro.ancho !== "") ? this.numeroFiltros++ : undefined;
      (this.filtro.alto !== null && this.filtro.alto !== "") ? this.numeroFiltros++ : undefined;
      

    },
    async actualizarResultados(){
      var spinner = document.getElementById("spinner")
      spinner.classList.remove("d-none")
      this.plazas = []

      let filtro = this.getFilter()
      const response = await axios.get('https://api.apparca.es/v1/api/parking_lots?' + filtro);
      const items = response.data.items

      items.forEach((plaza) => {
        plaza = (this.plazaOldMapper(plaza))
        this.plazas.push(plaza)
      })
      spinner.classList.add("d-none")
      this.actualizarNumerofiltros()
    },
    plazaOldMapper(plaza) {
      return  {
        id: plaza.id,
        direccion:plaza.direction,
        coordenadas: {
          latitud: plaza.coordinates.latitude,
          longitud: plaza.coordinates.longitude
        },
        isCoche: (plaza.vehicle_type == "car"),
        horas: {
          startTime: plaza.start_hour + ":" + plaza.start_minute,
          endTime: plaza.end_hour + ":" + plaza.end_minute
        },
        disponibilidad: [plaza.availability.monday,
        plaza.availability.tuesday, plaza.availability.wednesday, plaza.availability.thursday,
        plaza.availability.friday, plaza.availability.saturday, plaza.availability.sunday],
        largo: plaza.height,
        ancho: plaza.width,
        servicios: plaza.services,
        precio: plaza.monthly_price,
        preview: plaza.preview != null ? plaza.preview : "https://t4.ftcdn.net/jpg/04/73/25/49/360_F_473254957_bxG9yf4ly7OBO5I0O5KABlN930GwaMQz.jpg"
        
      }
    },
    formatTime(time) {
      const [hours, minutes] = time.split(':');
      const formattedHours = hours.length === 1 ? `0${hours}` : hours;
      const formattedMinutes = minutes.length === 1 ? `0${minutes}` : minutes;
      return `${formattedHours}:${formattedMinutes}`;
    },
    CambiarOrden(orden){
      this.orden = orden
      this.actualizarResultados()
    },
    PasarDatos(){
      this.$store.dispatch('PasarDatos', this.filtro)
      router.push({ name: 'VistaMapa' });
    },
    Fav(id){
      var indice = this.favoritos.indexOf(id);
      if (indice !== -1) {
        this.favoritos.splice(indice, 1);
        localStorage.setItem('favoritos', JSON.stringify(this.favoritos));
        const apiUrlFavorites = 'https://api.apparca.es/v1/api/favorites/'+ id;
        const headers = {
          Authorization: `Bearer ${this.token}`,
        };
        axios.delete(apiUrlFavorites, { headers })
        .then(() => {
        })
        .catch(error => {
          console.error(error);
        });
      }else{
        this.favoritos.push(id)
        localStorage.setItem('favoritos', JSON.stringify(this.favoritos));
        const apiUrlFavorites = 'https://api.apparca.es/v1/api/favorites';
        const headers = {
          Authorization: `Bearer ${this.token}`,
        };
        let objeto = {
          parking_lots : this.favoritos
        }
        axios.post(apiUrlFavorites, objeto, { headers })
        .then(() => {
        })
        .catch(error => {
          console.error(error);
        });
      }
    }
  },
  mounted(){
    if(this.filtroStore != null){
      this.llamadaDoble = true
      
      this.filtro = this.filtroStore
      if(this.filtro.latitud == undefined){
        this.filtro.latitud = "38.3451700"
      }
      if(this.filtro.longitud == undefined){
        this.filtro.longitud = "-0.4814900"
      }
      if(typeof this.filtro.horas == 'string'){
        this.filtro.horas = JSON.parse(this.filtro.horas)
      }
      this.$store.dispatch('limpiar')
      this.$refs.refFiltro.MeterDatos(this.filtro)
    }

    this.actualizarResultados()
  }
}

</script>

<style>


    .textoPrimario {
      color: #133C44;
    }

    .custom-dropdown .dropdown-toggle::after {
      display: none;
      /* Ocultar el icono de desplegar predeterminado */
    }

    .custom-dropdown .dropdown-toggle {
      background-image: url('../../src/assets/desplegable.png');
      /* Reemplaza 'ruta_de_la_imagen.png' con la URL de tu imagen */
      background-repeat: no-repeat;
      background-position: right center;
      /* Puedes ajustar la posición de la imagen */
      background-size: 20px;
      /* Ajusta el tamaño de la imagen según tus necesidades */
      padding-right: 30px;
      /* Ajusta el espacio para que la imagen no se superponga con el texto */
    }

    .icono {
      height: 25px;
      width:auto;
    }

    .filtroMovil {
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      border-radius: 0px 0px 20px 20px;
    }

    @media screen and (max-width: 992px) {
      .filtroVista{
        display: none;
      }
    }

    @media screen and (max-width: 768px) {
      .plazaItem {
        background-color: rgba(246, 246, 246, 0.511);
      }
    }
</style>
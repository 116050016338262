<template>
    <div class="container my-4">
        <h1>Sobre Apparca</h1>
    
        <p>Somos Apparca, una plataforma en línea que conecta a propietarios de plazas de garaje con personas que buscan alquilar plazas de garaje en toda España. Nuestra misión es facilitar la búsqueda y alquiler de plazas de garaje de manera segura y conveniente para todos nuestros usuarios.</p>

        <h2>Nuestra Historia</h2>
        <p>Apparca fue fundada en 2023 con la visión de resolver el problema común de encontrar aparcamiento en áreas urbanas y proporcionar a los propietarios de plazas de garaje una plataforma para alquilar sus espacios de estacionamiento de manera eficiente. Desde entonces, hemos trabajado incansablemente para mejorar y ampliar nuestros servicios, y hoy en día, somos una de las principales plataformas de alquiler de plazas de garaje en España.</p>

        <h2>Nuestro Equipo</h2>
        <p>Nuestro equipo está formado por profesionales apasionados y comprometidos en ofrecer la mejor experiencia a nuestros usuarios. Desde el desarrollo de la plataforma hasta la atención al cliente, cada miembro del equipo desempeña un papel fundamental en el éxito de Apparca.</p>

        <h2>Contacto</h2>
        <p>Si tiene alguna pregunta, comentario o sugerencia, no dude en ponerse en contacto con nosotros. Estamos aquí para ayudar.</p>
        <p><strong>Teléfono:</strong> 632161537</p>
        <p><strong>Correo Electrónico:</strong> contacto@apparca.es</p>

        <p>Agradecemos su confianza en Apparca y esperamos seguir sirviéndole en sus necesidades de aparcamiento.</p>
    </div>
  </template>
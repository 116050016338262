<template>
    <div class="container my-4">
        <h1>Política de Cookies de Apparca</h1>
        <p><strong>Fecha de entrada en vigencia:</strong> 23/10/2023</p>

        <p>Esta Política de Cookies describe cómo Apparca utiliza cookies y tecnologías similares en su plataforma. Al utilizar nuestros servicios, usted acepta el uso de cookies de acuerdo con esta política.</p>

        <h2>¿Qué son las Cookies?</h2>
        <p>Las cookies son pequeños archivos de texto que se almacenan en su dispositivo (ordenador, tablet, teléfono móvil, etc.) cuando visita sitios web. Las cookies se utilizan para recopilar información sobre su interacción con el sitio web, y pueden ser necesarias para su correcto funcionamiento.</p>

        <h2>¿Qué Cookies Utilizamos?</h2>
        <p>Apparca utiliza las siguientes categorías de cookies:</p>

        <h3>Cookies Estrictamente Necesarias</h3>
        <p>Estas cookies son esenciales para el funcionamiento del sitio web y le permiten navegar y utilizar sus características. Sin estas cookies, algunos servicios no pueden ser proporcionados.</p>

        <h3>Cookies de Rendimiento</h3>
        <p>Estas cookies recopilan información sobre cómo los visitantes utilizan el sitio web, como qué páginas visitan con más frecuencia. Esta información se utiliza para mejorar el rendimiento del sitio.</p>

        <h3>Cookies de Funcionalidad</h3>
        <p>Estas cookies permiten al sitio web recordar las elecciones que ha hecho, como su nombre de usuario, idioma o región, y proporcionan características mejoradas y personalizadas.</p>

        <h3>Otras Cookies de Terceros</h3>
        <p>En algunos casos, podemos permitir que terceros establezcan cookies en su dispositivo para proporcionar servicios, como la medición del tráfico y la publicidad personalizada. Estas cookies son gestionadas por terceros y están sujetas a sus propias políticas de privacidad y cookies.</p>

        <h2>Control de Cookies</h2>
        <p>Usted puede controlar y administrar las cookies a través de la configuración de su navegador. Tenga en cuenta que la desactivación de ciertas cookies puede afectar su experiencia en el sitio web.</p>

        <h2>Modificaciones de la Política de Cookies</h2>
        <p>Apparca se reserva el derecho de modificar esta política de cookies en cualquier momento. Cualquier cambio significativo se notificará a los usuarios a través de la plataforma.</p>

        <h2>Información de Contacto</h2>
        <p>Si tiene alguna pregunta o comentario sobre nuestra política de cookies, por favor, póngase en contacto con nosotros a través de contacto@apparca.es</p>
    </div>
</template>